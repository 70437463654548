import React from "react"
import { injectIntl } from "gatsby-plugin-intl"

import ContactForm from "../components/contactform"

const ContactFormPage = ({ intl }) => {
  return (
    <ContactForm
      intl={intl}
      openInputLabel={intl.formatMessage({
        id: "contact_form.open_input_generic",
        defaultMessage: "Have you ever had an interaction with the Cook County court system you want us to investigate? We want to hear from you."
      })}
    />
  )
}

export default injectIntl(ContactFormPage)